<template>
  <div style="position: absolute; top: 0; right: 0; z-index: 10" v-if="!online">
    <v-menu offset-y absolute>
      <template #activator="{ on, attrs }">
        <v-btn fab small class="text-center" elevation="2" color="#f4ae07" v-on="on" v-bind="attrs">
          <v-icon v-text="icons.mdiWifiOff" color="#f8f8ff"></v-icon>
        </v-btn>
      </template>

      <v-card :color="isDark ? '#302b49' : 'white'" width="300">
        <v-card-text>
          <span class="font-weight-bold"> Você está sem conexão com a internet! 😞 {{ isDark }} </span>
          <br />
          <span style="text-align: justify">
            Isso quer dizer que somente os dados já pesquisados e as páginas acessadas anteriormente serão exibidas.
          </span>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import toasts from '@/plugins/alert'
import useAppConfig from '@core/@app-config/useAppConfig'
import { mdiWifiOff } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'

export default {
  setup() {
    const online = ref(true)
    const { isDark } = useAppConfig()

    onMounted(() => (online.value = navigator.onLine ? true : false))

    window.addEventListener('online', () => {
      online.value = true
    })

    window.addEventListener('offline', () => {
      online.value = false
      toasts.warning('Você está navegando offline!')
    })

    return {
      online,
      isDark,
      icons: {
        mdiWifiOff,
      },
    }
  },
}
</script>
