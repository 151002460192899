<template>
  <div v-if="notificationPermission != 'granted'">
    <v-menu offset-y :close-on-content-click="false" v-model="menu">
      <template #activator="{ on, attrs }">
        <v-btn icon v-on="on" v-bind="attrs">
          <v-icon color="warning">{{ icons.mdiBell }}</v-icon>
        </v-btn>
      </template>

      <v-card dense width="300">
        <v-card-text>
          <span class="font-weight-bold" v-if="!isLoadingPermission"> Receba nossas notificações 🔔 </span>

          <div class="mt-2" v-if="!isLoadingPermission">
            Sabia que agora você pode receber todas as novidades relacionado a lista de música? basta aceitar clicando
            no botão abaixo
          </div>

          <div v-else>
            <v-icon v-text="icons.mdiArrowTopLeft" color="primary"></v-icon>
            Clique no botão <span class="text-no-wrap success white--text">permitir</span> que está aparecendo na sua
            tela
          </div>
        </v-card-text>

        <v-card-actions>
          <v-btn block color="success" @click="requestPermission()" v-if="!isLoadingPermission"> aceitar </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import PushNotification from '@/pwa/PushNotification'
import { subscribe } from '@/repositories/pushNotificationRepository'
import store from '@/store'
import { mdiArrowTopLeft, mdiBell } from '@mdi/js'
import { computed, onMounted, ref, watch } from '@vue/composition-api'

export default {
  setup() {
    const icons = {
      mdiBell,
      mdiArrowTopLeft,
    }
    const menu = ref(false)

    const notificationPermission = ref(Notification.permission)
    const pushManager = computed(() => store.state.pushManager)
    const user = computed(() => store.state.user)
    const localStorageSubscribeSuccessIndex = 'subscribeSuccess'

    const isLoadingPermission = ref(false)

    onMounted(() => {
      watch(pushManager, value => {
        if (localStorage[localStorageSubscribeSuccessIndex] === 'false') subscribeToPushNotification()
      })
    })

    function requestPermission() {
      isLoadingPermission.value = true

      Notification.requestPermission()
        .then(permission => {
          if (permission != 'granted') return console.error('Permissão negada')

          notificationPermission.value = permission
          subscribeToPushNotification()
        })
        .catch(error => {
          console.error(`Falha ao solicitar permissão ${error}`)
        })
        .finally(() => {
          isLoadingPermission.value = false
        })
    }

    function subscribeToPushNotification() {
      const pushNotification = new PushNotification(pushManager.value)

      pushNotification
        .subscribe()
        .then(sub => {
          subscribeUser(sub.toJSON())
        })
        .catch(error => {
          console.error(`Erro ao se inscrever ${error}`)
        })
        .finally(() => {
          menu.value = false
        })
    }

    function subscribeUser(sub) {
      const {
        endpoint,
        expirationTime,
        keys: { p256dh, auth },
      } = sub

      subscribe({
        endpoint,
        expirationTime,
        p256dh,
        auth,
        user_id: user.value.id,
      })
        .then(res => {
          console.log({ res })
          localStorage[localStorageSubscribeSuccessIndex] = true
        })
        .catch(error => {
          console.error({ error })
          localStorage[localStorageSubscribeSuccessIndex] = false
        })
    }

    return {
      requestPermission,
      notificationPermission,
      icons,
      subscribe,
      subscribeToPushNotification,

      isLoadingPermission,
      menu,
    }
  },
}
</script>
