<template>
  <div class="app-footer">
    <v-row no-gutters class="pt-2">
      <v-col v-if="!$vuetify.breakpoint.mobile" cols="12" lg="5" class="text-center">
        <p>Tenha uma lista de música para videokê sempre com você!!!</p>
      </v-col>
      <v-col class="text-center" cols="12" lg="2">
        <div class="d-flex align-center justify-center">
          <a
            href="https://api.whatsapp.com/send?phone=5511947466026&text=Gostaria%20de%20alugar%20uma%20m%C3%A1quina%20com%20voc%C3%AA!"
            target="_blank"
          >
            <v-icon size="40" color="light-green" v-text="icons.mdiWhatsapp"></v-icon>
            <h4>(11) 9 4746-6026</h4>
          </a>
        </div>
      </v-col>
      <v-col cols="12" lg="5" class="text-center"> Atualizado até o pacote {{ lastPackage }} </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" class="text-center">
        <div>
          © {{ new Date().getFullYear() }} Copyright
          <div>
            <v-icon v-text="icons.mdiGithub"></v-icon>
            <a href="https://github.com/j-yoharu" target="_blank">J-Yoharu</a> /
            <a href="https://github.com/japostulo" target="_blank">Japostulo</a>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiWhatsapp, mdiGithub } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiWhatsapp,
        mdiGithub,
      },
      lastPackage: '39G',
      version: null,
    }
  },
}
</script>

<style scoped>
.app-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.16);
}
</style>
