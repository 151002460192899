import store from '@/store'

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('service-worker.js')
    .then(serviceWorkerRegistration => {
      console.log('Service Worker Instalado com sucesso!')

      store.commit('pushManager', serviceWorkerRegistration.pushManager)
    })
    .catch(error => {
      console.error({ error })
    })
}
