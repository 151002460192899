import client from '@/plugins/client'

const prefix = 'push-notifications'

/**
 *
 * @param { Object } subscribe
 * @param { String } subscribe.endpoint
 * @param { Object } subscribe.keys
 * @param { String } subscribe.keys.p256dh
 * @param { String } subscribe.keys.auth
 * @returns { Promise }
 */
export const subscribe = subscribe => client.post(`${prefix}/`, subscribe)

export default {
  subscribe,
}
