<template>
  <div>
    <InternetStatus />
    <component :is="resolveLayoutVariant" :class="`skin-variant--${appSkinVariant}`">
      <transition :name="appRouteTransition" mode="out-in" appear>
        <router-view></router-view>
      </transition>
    </component>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed, onMounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
import { useLayout } from '@core/layouts/composable/useLayout'
import { useRouter } from '@core/utils'

// Layouts
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'
import LayoutContentHorizontalNav from '@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import useDynamicVh from '@core/utils/useDynamicVh'

import { index } from '@/repositories/musicRepository'

import InternetStatus from '@/components/InternetStatus.vue'

// Dynamic vh

export default {
  components: {
    LayoutContentVerticalNav,
    LayoutContentHorizontalNav,
    LayoutBlank,
    InternetStatus,
  },
  setup() {
    const { route } = useRouter()
    const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()

    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()

    const resolveLayoutVariant = computed(() => {
      const { layout } = route.value.meta
      switch (layout) {
        case 'blank':
          return 'layout-blank'
        default:
          return `layout-content-${appContentLayoutNav.value}-nav`
      }
    })

    useDynamicVh()

    onMounted(() => {
      migrateOldFavoritiesSongs()
    })

    const migrateOldFavoritiesSongs = () => {
      if (localStorage.favorities) return
      if (!localStorage.dbKaraoke) return
      try {
        let musicsDb = JSON.parse(localStorage.dbKaraoke)

        const ids = musicsDb.filter(music => music.favorite == true).map(music => music.cod)

        index({ ids, withOutPagination: true }).then(({ data }) => {
          localStorage.setItem('favorities', JSON.stringify(data))
          localStorage.setItem('favoritiesIds', JSON.stringify(ids))
          localStorage.removeItem('dbKaraoke')
        })
      } catch (e) {
        console.error('Falha ao importar os favoritos.')
      }
    }

    return {
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
      migrateOldFavoritiesSongs,
    }
  },
}
</script>
