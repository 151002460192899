import { urlBase64ToUint8Array } from '@/helpers/conversion'

export default class PushNotification {
  pushManager

  constructor(pushManager) {
    if ('serviceWorker' in navigator && 'PushManager' in window) console.log('Push Notification é suportado')
    else throw 'Navegador não possui suporte a Push Notification'

    this.pushManager = pushManager

    // Notification.requestPermission().then(permission => {
    //   if (permission != 'granted') throw 'Notificação não foi aceita!'
    // })
  }

  /**
   *
   * @param {String} title
   * @param {object} options
   * @param options.body O corpo da notificação, que é exibido abaixo do título.
   * @param options.icon O caminho para o ícone que será exibido na notificação.
   * @param options.image O caminho para uma imagem que será exibida na notificação.
   * @param options.badge O caminho para o ícone que será exibido no canto superior direito da notificação.
   * @param options.vibrate Uma sequência de números que representam durações de vibrações em milissegundos.
   * @param options.renotify Um valor booleano que indica se uma notificação já exibida deve ser reexibida ou substituída por uma nova.
   * @param { Boolean } options.requireInteraction Um valor booleano que indica se a notificação deve ser mantida na tela até que o usuário interaja com ela.
   * @param { object } options.data Um objeto que pode conter dados adicionais que serão enviados com a notificação.
   * @param { Array } options.actions Uma matriz de objetos que descrevem ações que podem ser realizadas pelo usuário na notificação.
   */
  showNotification(title, options = {}) {
    const notification = new Notification(title, options)

    notification.addEventListener('click', ({ target }) => {
      window.open(target.data.url, '_blank')
    })

    // const notification = new PushNotification()

    // notification.showNotification('Venha conferir nossas músicas', {
    //   body: 'Teste',
    //   icon: 'images/icons/16x16-icon.png',
    //   image: 'images/app/home.png',
    //   badge: 'images/icons/44x44-icon.png',
    //   vibrate: [200, 100, 200],
    //   requireInteraction: true,
    //   data: {
    //     url: 'https://listademusicas.com.br',
    //   },
    // })
  }

  subscribe() {
    const convertedKey = urlBase64ToUint8Array(process.env.VUE_APP_PUBLIC_KEY)

    return this.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: convertedKey,
    })
    // .then(res => {
    //   subscription = res.toJSON()
    //   console.log('Inscrito no Push Manager com sucesso!')
    // })
    // .catch(error => {
    //   console.log(`Erro para se inscrever no Push Manager: ${error}`)
    // })
  }

  async unsubscribe() {
    let unsubscribePromise
    await serviceWorkerRegistration.pushManager.getSubscription().then(subscription => {
      unsubscribePromise = subscription.unsubscribe()
    })
    return unsubscribePromise
  }
}
