export const urlBase64ToUint8Array = url => {
  const padding = '='.repeat((4 - (url.length % 4)) % 4)
  const base64 = (url + padding).replace(/\-/g, '+').replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }

  return outputArray
}
