const prefix = 'musics'
import client from '@/plugins/client'

export const musicsPackages = (params = {}) => {
  return new Promise((resolve, reject) => {
    resolve({ data: ['37A', '37B', '37C', '37D', '37E'] })
  })
  // client.get(`${prefix}/packages`, { params })
}
export const autocomplete = (text, params) => client.get(`/${prefix}/autocomplete`, { params: { text, ...params } })

export const searchMusic = (page = 1, params) => client.get(`/${prefix}/search`, { params: { ...params, page } })
export const index = (args = {}) => client.get(`/${prefix}`, { params: args })

export default {
  musicsPackages,
}
